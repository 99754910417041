import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import checkBlue from "../../assets/images/check-blue.svg"
import ducumentImage from "../../assets/images/file-document-arrow-right-outline.svg"
import cam from "../../assets/images/camera-outline.svg"
import arrow from "../../assets/images/arow-ritgh-gray.svg"
import { resizeImage } from '../../helpers/Resizer';

export function AttachmentCaptureScreen ({
    title, stepNumber, orientation,
    img, handleUpload, setSelectedFile,
    selectedFile, details, handleNextStep,
    isIncidentReport
}){
    const [isOpenModal, setIsOpenModal] = useState(false)

    const handleViewModal = ()=>{
        setIsOpenModal(!isOpenModal)
    }

    const handleOnClick = ()=>{
        if(selectedFile){
            handleUpload(details)
        }
    }

    const onFileChange = async (event) => {
        const file = event.target.files[0];
    
        if (file) {
          let resizedFile = file;
          if (file.type !== "application/pdf") {
            resizedFile = await resizeImage(file);
          }
    
          const reader = new FileReader();
    
          reader.onloadend = (e) => {
            setSelectedFile(e.target.result);
          };
    
          reader.readAsDataURL(resizedFile);
        }

        handleViewModal()

    };

    useEffect(()=>{
        if((details && details.Foto )|| (!isIncidentReport && stepNumber === "3")){
            handleNextStep()
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    
    return <Container>
        <Header>
            <h3>{title}</h3> <span>{stepNumber}/8 Fotos</span>
        </Header>

        {selectedFile ?
                <ImageCnh>
                         <img src={selectedFile} alt='CNH' />
                </ImageCnh>
            : 
            <>
                <ul>
                {
                    orientation.map((element, index)=>{
                        return <li key={index}>
                        <img src={checkBlue} alt='check' />
                        <p>{element}</p>
                    </li>
                    })
                }
                </ul>
                <CnhBox onClick={handleViewModal}>
                    <img src={img} alt='CNH' />
                </CnhBox>
            </>
        }

        <div>
            <button 
            onClick={handleOnClick}
            style={selectedFile? 
                {backgroundColor:"rgba(90, 120, 255, 1)", color:"white"}:
                {backgroundColor:"rgba(213, 213, 213, 1)", color:"rgba(81, 81, 81, 1)"}}
            >
                Continuar
            </button>
            {isOpenModal && 
                <Modal>
                    <div onClick={handleViewModal} />
                    <ul>
                        <li>
                                <input type='file' accept=".jpg, .jpeg, .png, .webp" onChange={onFileChange} />
                            <div>
                                <img src={ducumentImage} alt='' />
                                Anexar arquivo
                            </div>
                            <img src={arrow} alt='' />
                        </li>
                        <li>
                            <input type='file' accept=".jpg, .jpeg, .png, .webp" onChange={onFileChange} />
                            <div>
                                <img src={cam} alt='' />
                                Tirar foto
                            </div>
                            <img src={arrow} alt='' />
                        </li>
                    </ul>
                </Modal>
            }
        </div>
    </Container>
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;

    margin-top: 16px;
    min-height: 600px;

    & > ul{
        display: flex;
        flex-direction: column;
        gap: 12px;

        padding: 0;
        margin: 0;

        li{
        display: flex;
        align-items: start;
        gap: 8px;

            p{
                font-size: 14px;
                line-height: 18px;
                color: rgba(153, 153, 153, 1);
            
            }
        }
    }

    button{
        width: 100%;
        height: 45px;
        border: 0;
        border-radius: 4px;
    }

`

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 8px;

    h3{
        font-size: 20px;
        font-weight: 700;
        color: rgba(90, 120, 255, 1);
        margin: 0;
        line-height: 24.2px;
    }

    span{
        font-size: 12px;
        color: rgba(161, 164, 171, 1);
    }

`

const CnhBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 223px;

`
const ImageCnh = styled.div`
    min-height: 320px;
    display: flex;
    align-content: center;
    justify-content: center;
`

const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: end;
    z-index: 1000;
    background-color: rgba(81, 81, 81, 0.25);

    & >div{
        width: 100%;
        height: 100%;
    }

    ul{
        width: 100%;
        height: 128px;
        background-color: white;
        border-radius: 24px 24px 0px 0px;
        padding: 8px 0px 8px 0px;

        margin: 0;
    }

    li{
        display: flex;
        align-items: center;
        justify-content: space-between;

        height: 56px;
        padding-inline: 20px;

        position: relative;
        div{
            display: flex;
            align-items: center;
            gap: 12px;
        }

        svg{
            width: 24px;
            height: 24px;
        }
    }

    input{
        position: absolute;
        opacity: 0;

        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
    }
`