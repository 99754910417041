import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Lockr from "lockr";

import { GetFireWebToCase } from "../../services/SalesForceService";
import { PutAuxiliaryDocuments } from "../../services/AuxiliaryDocuments";

// components
import Header from "../Header";
import NavBar from "./NavBar";
import { Container, Row, Col } from "react-bootstrap";
import Feedback from "../../components/painel/Feedback";
import LoaderAnimation from "../../components/LoaderAnimation";
import Sidebar from "../painel/Sidebar";
import { MobileView, BrowserView } from "react-device-detect";

const Content = (props) => {
  const [clientId, setClientId] = useState("");
  const [contractId, setContractId] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isDataModal, setIsDataModal] = useState(false);
  const [isDataDocumentModal, setIsDataDocumentModal] = useState(false);
  const [completedStepsOpen, setCompletedStepsOpen] = useState(false);
  const [selectedStepIndex, setSelectedStepIndex] = useState(null);
  const [stepCurrent, setStepCurrent] = useState("Análise");
  const [caseStatus, setCaseStatus] = useState({
    Status: "New",
    CaseNumber: "",
    Reason: "",
    Fase__c: "",
    CreatedDate: "",
  });

  const handleDivClick = (index) => {
    setSelectedStepIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleCompletedStepsClick = () => {
    setCompletedStepsOpen(!completedStepsOpen);
  };

  const [steps, setSteps] = useState([
    {
      title: "Abertura",
      description: "",
      completed: false,
      status: "atual",
    },
    {
      title: "Documentação",
      description: "",
      completed: false,
      status: "futuros",
    },
    {
      title: "Análise",
      description:
        "Nesta etapa, verificamos suas coberturas, o evento relatado e os documentos enviados.",
      completed: false,
      status: "futuros",
    },
    {
      title: "Direcionamento",
      description:
        "Nesta etapa, determinamos a extensão do dano e sugerimos a oficina credenciada mais próxima.",
      completed: false,
      status: "futuros",
    },
    {
      title: "Regulação",
      description:
        "Nesta etapa, avaliamos os custos de mão de obra e peças para autorizar o conserto do veículo.",
      completed: false,
      status: "futuros",
    },
    {
      title: "Participação Obrigatória",
      description:
        "Nesta etapa, comunicamos o valor do pagamento obrigatório e oferecemos instruções para realizar o pagamento.",
      completed: false,
      status: "futuros",
    },
    {
      title: "Veículo em Reparo",
      description:
        "Estamos monitorando de perto o reparo do seu veículo para garantir que tudo corra conforme o planejado.",
      completed: false,
      status: "futuros",
    },
    {
      title: "Finalização",
      description: "Make sure to complete step 3",
      completed: false,
      status: "futuros",
    },
  ]);

  const nonCompletedStepsCount = steps.filter((step) => !step.completed).length;

  const completedStepsCount = steps.filter((step) => step.completed).length;

  useEffect(() => {
    async function loadData() {
      let value;
      let clientData;

      if (props.nCtrId) {
        clientData = Lockr.get("ClientData");

        value = clientData.customerCars.filter((contracts) => {
          return contracts.contractInfo.nCtrId === props.nCtrId;
        });

        Lockr.set("ClientData", {
          IdCustomerSelectedCar: value[0].value,
          customerSelectedCar: value[0],
        });

        const customerSelectedCar = clientData.customerSelectedCar;
        const contractInfo = customerSelectedCar.contractInfo;
        setClientId(contractInfo.bpName);
        setContractId(contractInfo.nCtrId);
      } else {
        clientData = Lockr.get("ClientData");
        const customerSelectedCar = clientData.customerSelectedCar;
        const contractInfo = customerSelectedCar.contractInfo;
        setClientId(contractInfo.bpName);
        setContractId(contractInfo.nCtrId);
      }
    }

    loadData();
  }, [props.nCtrId]);

  useEffect(() => {
    if(contractId.length > 0){
      const loadCase = async () => {
        const response = await GetFireWebToCase(contractId)

        if (response) {
          const filteredResponse = response.filter(
            (item) => item.Status === "New"
          );

          setCaseStatus(filteredResponse[0]);
          setStepCurrent(filteredResponse[0].Fase__c);
          definedStepsStatus(filteredResponse[0].Fase__c);
        }
      };

      loadCase();
    }
  }, [contractId]);

  const definedStepsStatus = (stepCurrent) => {
    let currentIndex;
    const updatedSteps = steps.map((step, index) => {
      switch (stepCurrent) {
        case "Aberto":
          currentIndex = steps.findIndex((step) => step.title === "Abertura ");
          if (step.title === "Abertura") {
            return { ...step, status: "atual" };
          } else if (index < currentIndex) {
            return { ...step, status: "concluido", completed: true };
          } else {
            return { ...step, status: "futuros", completed: false };
          }
        case "Documentação":
          currentIndex = steps.findIndex(
            (step) => step.title === "Documentação "
          );
          if (step.title === "Documentação") {
            return { ...step, status: "atual" };
          } else if (index < currentIndex) {
            return { ...step, status: "concluido", completed: true };
          } else {
            return { ...step, status: "futuros", completed: false };
          }
        case "Análise":
          currentIndex = steps.findIndex((step) => step.title === "Análise");
          if (step.title === "Análise") {
            return { ...step, status: "atual" };
          } else if (index < currentIndex) {
            return { ...step, status: "concluido", completed: true };
          } else {
            return { ...step, status: "futuros", completed: false };
          }
        case "Direcionamento":
          currentIndex = steps.findIndex(
            (step) => step.title === "Direcionamento",
          );
          if (step.title === "Direcionamento") {
            return { ...step, status: "atual" };
          } else if (index < currentIndex) {
            return { ...step, status: "concluido", completed: true };
          } else {
            return { ...step, status: "futuros", completed: false };
          }
        case "Regulação":
          currentIndex = steps.findIndex((step) => step.title === "Regulação");
          if (step.title === "Regulação") {
            return { ...step, status: "atual" };
          } else if (index < currentIndex) {
            return { ...step, status: "concluido", completed: true };
          } else {
            return { ...step, status: "futuros", completed: false };
          }
        case "Comunicação Cliente":
          currentIndex = steps.findIndex(
            (step) => step.title === "Participação Obrigatória",
          );
          if (step.title === "Participação Obrigatória") {
            return { ...step, status: "atual" };
          } else if (index < currentIndex) {
            return { ...step, status: "concluido", completed: true };
          } else {
            return { ...step, status: "futuros", completed: false };
          }
        case "Acompanhamento":
        case "Pagamento":
        case "Monitorar Pagamento":
          currentIndex = steps.findIndex(
            (step) => step.title === "Veículo em Reparo",
          );
          if (step.title === "Veículo em Reparo") {
            return { ...step, status: "atual" };
          } else if (index < currentIndex) {
            return { ...step, status: "concluido", completed: true };
          } else {
            return { ...step, status: "futuros", completed: false };
          }
        case "Concluído":
          if (step.title === "Finalização") {
            return { ...step, status: "concluido", completed: true };
          } else if (index < currentIndex) {
            return { ...step, status: "concluido", completed: true };
          }
          break;
        default:
          console.log(`Sorry, we are out of ${stepCurrent}.`);
          return step;
      }
    });

    setSteps(updatedSteps);
  };

  const openModalData = () => {
    setIsDataModal(true);
  };

  const closeModalData = () => {
    setIsDataModal(false);
  };

  const [stepsDocumentation, setStepsDocumentation] = useState([
    {
      title: "CNH Aberta",
      status: "Aprovada",
    },
    {
      title: "CRLV do automóvel",
      status: "Aprovada",
    },
    {
      title: "Boletim de  ocorrência",
      status: "Reprovado",
    },
    {
      title: "Dianteira do veículo com lateral do motorista",
      status: "Em análise",
    },
    {
      title: "Traseira do veículo com lateral do passageiro",
      status: "Em análise",
    },
    {
      title: "Primeira foto do dano ao veículo",
      status: "Em análise",
    },
    {
      title: "Segunda foto do dano ao veículo",
      status: "Em análise",
    },
    {
      title: "Orçamento",
      status: "Em análise",
    },
  ]);

  const openModalDocumentation = () => {
    setIsDataDocumentModal(true);
  };

  const closeModalDocumentation = () => {
    setIsDataDocumentModal(false);
  };

  return (
    <React.Fragment>
      <link
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
        rel="stylesheet"
      />
      <LoaderAnimation show={isLoading} />
      {!isDataModal && !isDataDocumentModal ? (
        <>
          <h3 className="loovi-header-title title-color">Solicitações</h3>
          {/*
            <div>
              <p className="update-title">Atualização</p>
            </div>
          */}
          <>
            <p className="update-title">Etapas</p>
            <>
              {completedStepsCount > 0 && (
                <>
                  {completedStepsOpen === true ? (
                    <div className="box-completed">
                      <div className="box-steps">
                        {steps
                          .filter((step) => step.completed)
                          .map((step, i) => (
                            <div
                              className="steps-content"
                              key={i}
                              style={{ height: "100%" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                {i !== 0 && (
                                  <div
                                    className="step-bar"
                                    style={{
                                      background: step.completed
                                        ? "#5a78ff"
                                        : "#E5E5E5",
                                      marginBottom: "-0.1rem",
                                    }}
                                  ></div>
                                )}
                                <div
                                  className="material-icons"
                                  style={{
                                    color: step.completed
                                      ? "#5a78ff"
                                      : "#E5E5E5",
                                  }}
                                >
                                  check_circle
                                </div>
                                <div
                                  className="step-bar"
                                  style={{
                                    background: step.completed
                                      ? "#5a78ff"
                                      : "#E5E5E5",
                                    marginTop: "-0.1rem",
                                  }}
                                ></div>
                              </div>
                            </div>
                          ))}
                      </div>
                      <div className="steps-completed-content">
                        <p
                          onClick={handleCompletedStepsClick}
                          className="material-icons"
                          style={{
                            fontSize: "24px",
                            textAlign: "end",
                            color: "#5a78ff",
                            marginTop: "0.2rem",
                            marginBottom: "0.2rem",
                          }}
                        >
                          keyboard_arrow_up
                        </p>
                        {steps
                          .filter((step) => step.completed)
                          .map((step, i) => (
                            <div className="steps-content" key={i}>
                              <div
                                className="step-completed"
                                style={{ marginTop: i !== 0 ? "1rem" : "0rem" }}
                              >
                                <p className="step-current-title">
                                  {step.title}
                                </p>
                                {selectedStepIndex === i && (
                                  <p className="step-current-description">
                                    {step.description}
                                  </p>
                                )}
                                {step.title === "Abertura" ? (
                                  <div
                                    className="step-current-info"
                                    onClick={openModalData}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <p>Concluído</p>
                                    <p
                                      className="material-icons"
                                      style={{
                                        fontSize: "12px",
                                        textAlign: "end",
                                        color: "#ffffff",
                                        marginLeft: "0.2rem",
                                      }}
                                    >
                                      arrow_forward
                                    </p>
                                  </div>
                                ) : step.title === "Documentação" ? (
                                  <div
                                    className="step-current-info"
                                    onClick={openModalDocumentation}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <p>Concluído</p>
                                    <p
                                      className="material-icons"
                                      style={{
                                        fontSize: "12px",
                                        textAlign: "end",
                                        color: "#ffffff",
                                        marginLeft: "0.2rem",
                                      }}
                                    >
                                      arrow_forward
                                    </p>
                                  </div>
                                ) : (
                                  <div className="step-current-info">
                                    <p>Concluído</p>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="box-completed"
                      onClick={handleCompletedStepsClick}
                    >
                      <div className="box-steps">
                        <div
                          className="steps-content"
                          style={{ height: "100%" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              className="material-icons"
                              style={{
                                color: "#5a78ff",
                              }}
                            >
                              check_circle
                            </div>
                            <div
                              className="step-bar"
                              style={{
                                background: "#5a78ff",
                                marginTop: "-0.1rem",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="step-completed-content">
                        <p className="step-values-completed">
                          {completedStepsCount > 1
                            ? `${completedStepsCount} etapas concluídas`
                            : `${completedStepsCount} etapa concluída`}
                        </p>
                        <p
                          className="material-icons"
                          style={{
                            fontSize: "24px",
                            textAlign: "end",
                            color: "#5a78ff",
                            marginTop: "0.2rem",
                            marginBottom: "0.2rem",
                          }}
                        >
                          keyboard_arrow_down
                        </p>
                      </div>
                    </div>
                  )}
                </>
              )}
              {steps
                .filter((step) => !step.completed)
                .map((step, i) => (
                  <div className="steps-content" key={i}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      {completedStepsCount > 0 && (
                        <div
                          className="step-bar"
                          style={{
                            background:
                              step.status === "atual" ? "#5a78ff" : "#E5E5E5",
                            marginBottom: "-0.1rem",
                          }}
                        ></div>
                      )}
                      <div
                        className="material-icons"
                        style={{
                          color:
                            step.status === "atual" ? "#5a78ff" : "#E5E5E5",
                        }}
                      >
                        radio_button_unchecked
                      </div>
                      {i === nonCompletedStepsCount - 1 ? (
                        <></>
                      ) : (
                        <div
                          className="step-bar"
                          style={{
                            background:
                              step.status === "atual" ? "#5a78ff" : "#E5E5E5",
                            marginTop: "-0.1rem",
                          }}
                        ></div>
                      )}
                    </div>
                    {step.status === "atual" ? (
                      <div
                        className="step-current"
                        onClick={() => handleDivClick(i)}
                        style={{ marginTop: "1rem" }}
                      >
                        <p className="step-current-title">{step.title}</p>
                        {selectedStepIndex === i && (
                          <p className="step-current-description">
                            {step.description}
                          </p>
                        )}
                        <div className="step-current-info">
                          <p>Em andamento</p>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="step-disabled"
                        style={{ marginTop: "1rem" }}
                      >
                        <p className="step-disabled-title">{step.title}</p>
                      </div>
                    )}
                  </div>
                ))}
            </>
          </>
        </>
      ) : isDataModal && !isDataDocumentModal ? (
        <>
          <h3
            className="loovi-header-title title-color"
            style={{ textAlign: "center", marginBottom: "3.5rem" }}
          >
            Abertura
          </h3>
          <div className="data-content">
            <div className="data-content" onClick={closeModalData}>
              <div className="data-content-text">
                <p className="data-title">MOTIVO DO CASO:</p>
                <p className="data-text">Colisão</p>
              </div>
              <div className="data-content-text">
                <p className="data-title">NUMERO DO CASO:</p>
                <p className="data-text">12312345671234</p>
              </div>
              <div className="data-content-text">
                <p className="data-title">DESCRIÇÃO DO OCORRIDO:</p>
                <p className="data-text">
                  Lorem ipsum dolor sit amet, consectetur adipisci elit, sed
                  eiusmod tempor incidunt ut labore et dolore magna aliqua.
                </p>
              </div>
              <div className="data-content-text">
                <p className="data-title">LOCAL DO SINISTRO:</p>
                <p className="data-text">
                  Rua Maria Carolina 204, centro, São Paulo-SP{" "}
                </p>
              </div>
              <div className="data-content-text">
                <p className="data-title">PONTO DE REFERÊNCIA:</p>
                <p className="data-text">Em frente ao supermercado ABC</p>
              </div>
              <div className="data-content-text">
                <p className="data-title">DATA/HORA DA OCORRÊNCIA:</p>
                <p className="data-text">03/05/2024 às 15:17</p>
              </div>
              <div className="data-content-text">
                <p className="data-title">CONDUTOR:</p>
                <p className="data-text">Samuel Machado Delfino</p>
              </div>
              <div className="data-content-text">
                <p className="data-title">CPF DO CONDUTOR:</p>
                <p className="data-text">111.222.333-44</p>
              </div>
              <div className="data-content-text">
                <p className="data-title">CONTATO DO CONDUTOR:</p>
                <p className="data-text">(00) 9 9191-9191</p>
              </div>
              <div className="data-content-text">
                <p className="data-title">VEÍCULO LOCOMOVENDO:</p>
                <p className="data-text">Sim</p>
              </div>
              <div className="data-content-text">
                <p className="data-title">BOLETIM REALIZADO:</p>
                <p className="data-text">Sim</p>
              </div>
              <div className="data-content-text">
                <p className="data-title">ENVOLVIMENTO COM TERCEIROS:</p>
                <p className="data-text">Sim</p>
              </div>
              <div className="data-content-text">
                <p className="data-title">ABRIR PROTOCOLO PARA TERCEIRO:</p>
                <p className="data-text">Sim</p>
              </div>
              <div className="data-content-text">
                <p className="data-title">CONTATO DO TERCEIRO:</p>
                <p className="data-text">(00) 9 9191-9191</p>
              </div>
              <div className="data-content-text">
                <p className="data-title">TERMOS E CONDIÇÕES:</p>
                <p className="data-text">Aceito em 10/05/2024</p>
              </div>
            </div>
            <button className="btn-download">
              <p className="material-icons" style={{ marginRight: "0.3rem" }}>
                download
              </p>
              <p>Baixar aviso de sinistro</p>
            </button>
          </div>
        </>
      ) : (
        <>
          <h3
            className="loovi-header-title title-color"
            style={{ textAlign: "center", marginBottom: "3.5rem" }}
          >
            DOCUMENTAÇÃO
          </h3>
          <div className="data-content" onClick={closeModalDocumentation}>
            {stepsDocumentation.map((step, i) => (
              <>
                <div className="documentation-content" key={i}>
                  <p className="documentation-content-text">{step.title}</p>
                  <div
                    className="status-documentation"
                    style={{
                      color:
                        step.status === "Aprovada"
                          ? "#02AF5C"
                          : step.status === "Reprovado"
                            ? "#ED3D3D"
                            : "#999999",
                      background:
                        step.status === "Aprovada"
                          ? "#DAFFED"
                          : step.status === "Reprovado"
                            ? "#FFEDED"
                            : "#EEF2FA",
                    }}
                  >
                    {step.status}
                  </div>
                </div>
                {i !== stepsDocumentation.length - 1 && (
                  <div
                    style={{
                      height: "1px",
                      background: "#EEF2FA",
                      width: "100%",
                      marginTop: "1rem",
                      marginBottom: "1rem",
                    }}
                  ></div>
                )}
              </>
            ))}
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default Content;

export class Consultation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      file: "",
    };
  }

  handleChange = (event) => {
    this.setState({ description: event.target.value });
  };

  handleChangeFile = (event) => {
    this.setState({ file: event.target.value });
  };

  render() {
    const { description, file } = this.state;
    const { contactPhone, setLoading } = this.props;
    const values = {
      description,
      file,
      contactPhone,
      setLoading,
    };
    return (
      <Section>
        <Header>
          <NavBar backButton blueArrow />
        </Header>
        <MobileView>
          <Container>
            <Row>
              <Col sm={12} md={{ span: 6, offset: 3 }}>
                <Content
                  values={values}
                  handleChange={this.handleChange}
                  handleChangeFile={this.handleChangeFile}
                />
              </Col>
            </Row>
          </Container>
        </MobileView>
        <BrowserView>
          <Container fluid>
            <Row>
              <div className="loovi-fixed-side-column">
                <Sidebar />
              </div>
              <div className="loovi-fluid-column">
                <Col sm={12} md={6}>
                  <div className="loovi-descktop-padding">
                    <Content
                      values={values}
                      handleChange={this.handleChange}
                      handleChangeFile={this.handleChangeFile}
                    />
                  </div>
                </Col>
              </div>
            </Row>
          </Container>
        </BrowserView>
      </Section>
    );
  }
}

const Section = styled.section`
  position: relative;
  font-size: 15px;
  color: #393043 !important;

  p {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.32px;
    line-height: 21px;
  }

  h4 {
    font-size: 15px;
    color: #899299;
    letter-spacing: -0.32px;
    text-align: center;
    line-height: 21px;
  }

  .loovi-descktop-padding {
    h3,
    h4 {
      text-align: left;
    }
  }

  text-color-Ivoce {
    color: #393043 !important;
  }

  .title-color {
    color: #a1a4ab !important;
  }

  .update-title {
    color: #515151;
    font-size: 16px;
    font-weight: 700;
    margin-top: 3rem;
  }

  .step-bar {
    height: 100%;
    width: 2px;
  }

  .steps-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .step-current {
    width: 268px;
    min-height: 64px;
    border-radius: 12px;
    padding: 12px;
    background: #5a78ff;
    box-shadow: 2px 2px 24px 0px #0000000a;
    box-shadow: 4px 4px 24px 0px #00000005;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }

  .step-current-title {
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
  }

  .step-current-info {
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .step-current-info p {
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
  }

  .step-disabled {
    width: 268px;
    min-height: 64px;
    border-radius: 12px;
    padding: 12px;
    background: #eef2fa;
    box-shadow: 2px 2px 24px 0px #0000000a;
    box-shadow: 4px 4px 24px 0px #00000005;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
  }

  .step-disabled-title {
    color: #999999;
    font-size: 14px;
    font-weight: 700;
  }

  .step-current-description {
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
    line-height: 14.52px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .box-completed {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .box-steps {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .steps-completed-content {
    width: 268px;
    background: #eef2fa;
    display: flex;
    flex-direction: column;
    padding: 8px 12px 12px 12px;
    border-radius: 12px;
    border: 1px solid #e0e0ee;
  }

  .step-completed {
    width: 244px;
    min-height: 64px;
    border-radius: 12px;
    padding: 12px;
    background: #5a78ff;
    box-shadow: 2px 2px 24px 0px #0000000a;
    box-shadow: 4px 4px 24px 0px #00000005;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }

  .step-current-title {
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
  }

  .step-completed-content {
    width: 268px;
    height: 33px;
    background: #eef2fa;
    padding: 8px 12px;
    border-radius: 8px;
    box-shadow: 2px 2px 24px 0px #0000000a;
    box-shadow: 4px 4px 24px 0px #00000005;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .step-values-completed {
    color: #254dff;
    font-size: 14px;
    font-weight: 700;
  }

  .data-content {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    margin-bottom: 1rem;
  }

  .data-content-text {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .data-title {
    font-size: 12px;
    font-weight: 700;
    line-height: 14.52px;
    letter-spacing: 0.09em;
    text-align: left;
    color: #515151;
    margin-top: 0.3rem;
  }

  .data-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #a1a4ab;
    margin-top: 0.3rem;
  }

  .btn-download {
    width: 300px;
    min-height: 48px;
    padding: 4px 0px;
    border-radius: 4px;
    color: #5a78ff;
    border: 1px solid #5a78ff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #ffffff;
  }

  .documentation-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .documentation-content-text {
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    text-align: left;
    color: #515151;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 240px;
    overflow: hidden;
  }

  .status-documentation {
    width: 85px;
    padding: 2px 8px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 300;
    line-height: 22px;
    text-align: center;
  }
`;
